import { Item, Section } from './index.styled';
import { Container } from 'components/container';
import data from './data.json';
import Slider, { Settings } from 'react-slick';
import { useParallax } from 'react-scroll-parallax';
import { Button } from 'primereact/button';
import parse from 'html-react-parser';
import { useState } from 'react';
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti';

const BlogCRV = () => {
  const [slider1, setSlider1] = useState<Slider>(null);
  const [slider2, setSlider2] = useState<Slider>(null);
  const [cSlide, setCSlide] = useState(0);

  var settings = {
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  } as Settings;

  const parallax = useParallax<HTMLDivElement>({
    translateY: [-10, 10],
    speed: -50,
  });

  return (
    <Section>
      <Container fluid>
        <div className="grid">
          <div className="col-12 md:col-6 p-0 wrap">
            <Slider
              {...settings}
              ref={slider => setSlider1(slider)}
              asNavFor={slider2}
              fade
              afterChange={currentSlide => setCSlide(currentSlide)}
            >
              {data.map((item, i) => (
                <div key={i} className="img-wrapper" ref={parallax.ref}>
                  <img src={item.img} />
                </div>
              ))}
            </Slider>
            <div className="controls">
              <Button
                disabled={cSlide === data.length - 1 ? true : false}
                className="p-button-rounded p-button-text nav next"
                onClick={() => slider1?.slickNext()}
              >
                <TiArrowSortedUp />
              </Button>
              <div className="pagination">
                <span className="current">{cSlide + 1}</span>
                <span className="total">{data.length}</span>
              </div>
              <Button
                disabled={cSlide === 0 ? true : false}
                className="p-button-rounded p-button-text nav prev"
                onClick={() => slider1?.slickPrev()}
              >
                <TiArrowSortedDown />
              </Button>
            </div>
          </div>
          <div className="col-12 md:col-6 p-0 mt-4 md:mt-0">
            <Slider {...settings} ref={slider => setSlider2(slider)} asNavFor={slider1}>
              {data.map((item, i) => (
                <div key={i} className="content">
                  <h6>{item.tag}</h6>
                  <div className="grid info">
                    {item.info.map((v, j) => (
                      <Button key={i + '' + j} label={v.label} className="p-button-link " />
                    ))}
                  </div>
                  <h3>{parse(item.label)}</h3>
                  <p>{parse(item.text)}</p>
                  <Button label={item.action.label} className="p-button-text action" />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default BlogCRV;
