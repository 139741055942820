import styled from 'styled-components';

export const Section = styled.section`
  background: #212329;
  overflow: hidden;
  position: relative;
  padding: 20px;

  .img-wrapper {
    border-radius: 3px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }

  .content {
    position: relative;
    padding: 30px 65px;
    text-align: center;

    h6 {
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 13px;
      margin-bottom: 20px;
      font-weight: 400;
    }

    .info {
      justify-content: center;
      margin: 0;
      margin-bottom: 10px;

      button {
        padding: 0;
        margin: 0 10px;
        font-weight: 400;
        font-size: 13px;
      }
    }

    h3 {
      margin: 0;
      font-weight: 600;
      line-height: 1.5;
      font-size: 30px;
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 2;
      margin: 0;
      margin-bottom: 20px;
    }

    .action {
      font-size: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  .controls {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    line-height: 100px;
    text-align: center;
    background: #fff;
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    z-index: 3;

    &:after {
      content: '';
      position: absolute;
      right: -10px;
      top: -10px;
      bottom: -10px;
      left: -10px;
      background: rgba(220, 220, 220, 0.2);
      border-radius: 50%;
      z-index: -1;
    }

    .pagination {
      position: static;
      span {
        margin: 0 7px;
        font-weight: 600;
        color: #444;
        display: inline-block;
        position: relative;

        &.current {
          &:after {
            content: '/';
            position: absolute;
            right: -10px;
            top: 0;
            font-size: 10px;
            color: #ccc;
          }
        }
      }
    }

    .nav {
      position: absolute;
      left: calc(50% - 20px);
      right: auto;
      color: #ccc;
      z-index: 4;
      cursor: pointer;
      font-size: 40px;
      min-width: auto;
      padding: 0px;

      &.next {
        top: 0px;
        bottom: auto;
      }

      &.prev {
        bottom: 0px;
        top: auto;
      }
    }
  }

  @media (max-width: 576px) {
    .content {
      padding: 30px 15px;

      h3 {
        font-size: 20px;
      }
    }

    .wrap {
      position: relative;

      .controls {
        width: 70px;
        height: 70px;
        line-height: 73px;
        top: calc(100% - 35px);
        left: calc(50% - 35px);

        &:after {
          right: -5px;
          top: -5px;
          bottom: -5px;
          left: -5px;
        }

        .nav {
          left: calc(50% - 15px);
          font-size: 30px;

          &.next {
            top: -2px;
          }

          &.prev {
            bottom: -2px;
          }
        }
      }
    }
  }
`;

export const Item = styled.div`
  padding: 10px 10px 0;

  img {
    width: 100%;
    height: auto;
  }
`;
