import PrimeReact from 'primereact/api';
// import "primereact/resources/themes/md-light-indigo/theme.css";
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import ScrollToTop from './utils/ScrollToTop';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from 'pages/home';
import Header from 'components/header';
import Footer from 'components/footer';
import AboutPage from 'pages/about';
import ContactPage from 'pages/contact';
import TeamPage from 'pages/team';

function App() {
  // active ripple effect
  PrimeReact.ripple = true;

  return (
    <>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <ScrollToTop />
        <Header />
        <div className="wrap">
          <Routes>
            <Route path="team" element={<TeamPage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="" element={<HomePage />} />
            <Route path="*" element={<Navigate to="" />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
