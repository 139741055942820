import styled from 'styled-components';

export const LargeText = styled.h1`
  letter-spacing: 0;
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  margin: 0;
  padding: 0;
  border: 0;

  @media (min-width: 576px) {
  }
`;

export const SubText = styled.p`
  font-weight: 300;
  font-size: 23px;
  line-height: 28px;
  margin: 0;
  padding: 0;
  border: 0;

  @media (min-width: 576px) {
  }
`;

export const BGTitleText = styled.h6`
  font-weight: 300;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 4px;
  display: inline-block;
  background: linear-gradient(to right, var(--info-color), var(--help-color), var(--danger-color));
  padding: 7px 12px;
  border-radius: 10px;

  @media (min-width: 576px) {
  }
`;

export const GradiantText = styled.h3`
  background: linear-gradient(to right, var(--info-color), var(--help-color), var(--danger-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (min-width: 576px) {
  }
`;
