import { Container } from 'components/container';
import { Image } from 'primereact/image';
import { Section } from './index.styled';
import Heading from '../heading';

const Growth = () => {
  return (
    <Section>
      <Image src="/assets/images/team/Untitled presentation_2_page-0004.jpg" />
      <div className="overlay">
        <Container>
          <Heading text="CONTINUOUS CUSTOMER GROWTH JOURNEY" lineColor="dark" />
        </Container>
      </div>
    </Section>
  );
};

export default Growth;
