import { Dialog as D } from 'primereact/dialog';
import ReactPlayer from 'react-player/lazy';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import styled from 'styled-components';

const Dialog = styled(D)`
  padding: 0 !important;
  box-shadow: none !important;

  .p-dialog-header {
    display: none;
  }

  .p-dialog-content {
    padding: 0px;

    .player-wrapper {
      width: 100vw;
      max-width: 640px;
      position: relative;
      overflow: hidden;
      padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    }

    .react-player,
    .brightcove-react-player-loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;

      video-js {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

interface PropsType {
  show: boolean;
  onHide: () => void;
  className?: string;
  videoUrl: string;
}
const PlayerDialog = ({ className = '', show, onHide, videoUrl }: PropsType) => {
  return (
    <Dialog visible={show} onHide={onHide} className={className} dismissableMask>
      <div className="player-wrapper">
        {/* <ReactPlayer
          className="react-player"
          url={videoUrl}
          controls
          config={{
            vimeo: {
              title: 'video',
            },
          }}
        /> */}
        <ReactPlayerLoader accountId="6264577224001" videoId={videoUrl} />
      </div>
    </Dialog>
  );
};
export default PlayerDialog;
