import { Container } from 'components/container';
import { Image } from 'primereact/image';
import { Section } from './index.styled';
import data from './data.json';
import Heading from '../heading';

const Partners = () => {
  return (
    <Section>
      <Container>
        <Heading text="ECOSYSTEM PARTNERS" textColor="dark" lineColor="dark" />
        <div className="mb-5 grid align-items-center">
          {data.map((p, i) => (
            <div key={i} className="col-12 md:col-3">
              <Image
                src={p.src}
                width={p.auto === 'width' ? 'auto' : '100%'}
                height={p.auto === 'height' ? 'auto' : '100%'}
                className={p.auto === 'width' ? 'center' : 'left'}
              />
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default Partners;
