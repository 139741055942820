import { Container } from 'components/container';
import { Image } from 'primereact/image';
import { Section } from './index.styled';
import Heading from '../heading';
import { ArrowDivider } from 'components/ArrowDivider';

const BusinessModal = () => {
  return (
    <Section>
      <Container>
        <Heading text="Business model" />
        <h4 className="title my-5">
          CrowdPoint boosts commerce and capital in an open ecosystem by driving activity and incentivizing
          participation
        </h4>

        <div className="grid align-items-center justify-content-between mt-5 pt-5">
          <div className="box1 text-center">
            <Image src="/assets/images/team/photo-1612010167108-3e6b327405f0.jpg" className="pw" />
            <h2 className="mt-5">Commercial Markets</h2>
            <p className="des mt-0">Distributed E-Commerce</p>
          </div>
          <ArrowDivider />
          <div className="box2 text-center">
            <h2>Data</h2>
            <p className="des mt-0">Online Retail Rebate Program Customer Matching Digital Banking Fundraising</p>
            <h2>Value</h2>
            <p className="des mt-0">Transactions Subscriptions Payment Processing Banking Fees API Calls</p>
          </div>
          <ArrowDivider />
          <div className="box1 text-center">
            <Image src="/assets/images/team/photo-1620714223084-8fcacc6dfd8d.jpg" className="pw" />
            <h2 className="mt-5">Capital Markets</h2>
            <p className="des mt-0">Financial Services</p>
          </div>
        </div>

        <div className="grid align-items-center justify-content-between mt-5 pt-5 timeline">
          <div className="col-4 p-0">
            <p>Owner shares data</p>
          </div>
          <div className="col-4 p-0">
            <p className="center">Intelligence obliges others</p>
          </div>
          <div className="col-4 p-0">
            <p className="right">Owner gets reimbursed</p>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default BusinessModal;
