import { Item, Section } from './index.styled';
import { Container } from 'components/container';
import data from './data.json';
import { GradiantText } from 'components/text';
import { classNames } from 'primereact/utils';
import { LineDivider } from 'components/LineDivider';
import { useEffect, useRef, useState } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import Isotope from 'isotope-layout';
import { Button } from 'primereact/button';

const ItemTemplate = ({ className = '', image, label, text }) => {
  return (
    <Item className={className}>
      <div className="item-img">
        <Button className="p-button-text p-0">
          <img src={image} alt="image" />
        </Button>
      </div>
      <div className="cont">
        <h6>{text}</h6>
        <h5>{label}</h5>
      </div>
    </Item>
  );
};

const Portfolio = () => {
  const isotope = useRef<Isotope | null>();
  const [filterIndex, setFilterIndex] = useState(0);
  const items = [
    { label: 'All', value: '*' },
    { label: 'Branding', value: 'brand' },
    { label: 'Mobile App', value: 'web' },
    { label: 'Creative', value: 'graphic' },
  ];

  useEffect(() => {
    isotope.current = new Isotope('.filter-container', {
      itemSelector: '.filter-item',
      layoutMode: 'fitRows',
    });
    // cleanup
    return () => isotope.current?.destroy();
  }, []);

  useEffect(() => {
    filterIndex === 0
      ? isotope.current.arrange({ filter: `*` })
      : isotope.current.arrange({ filter: `.${items[filterIndex].value}` });
  }, [filterIndex]);

  return (
    <Section id="portfolio-section">
      <LineDivider top right />
      <Container fluid>
        <div className="grid">
          <div className="col-12 md:col-8 md:col-offset-2 sec-head">
            <h6>PORTFOLIO</h6>
            <GradiantText>
              RECENT ONLINE PROJECTS
              <br /> BUILT USING OUR TEMPLATES!
            </GradiantText>
          </div>
        </div>
        <div className="grid">
          <div className="col-12 p-0">
            <TabMenu model={items} activeIndex={filterIndex} onTabChange={e => setFilterIndex(e.index)} />
          </div>
        </div>
        <div className="grid m-0 filter-container">
          {data.map((item, i) => (
            <div key={i} className={classNames('col-12 md:col-6 p-0 filter-item', item.cat)}>
              <ItemTemplate {...item} className={classNames({ big: i % 2 === 0 })} />
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default Portfolio;
