import { Container } from 'components/container';
import { Image } from 'primereact/image';
import { Section } from './index.styled';
import Heading from '../heading';
import data from './logoData.json';

const WhyNow = () => {
  return (
    <Section>
      <div className="grid m-0">
        <div className="col-12 md:col-6 box1">
          <Container>
            <Heading text="WHY NOW" textColor="dark" lineColor="dark" />
            <h5>Business Ecosystem</h5>
            <h2>
              Business ecosystems extract
              <br /> value for themselves.
            </h2>

            <div className="grid align-items-center mt-5 pt-5">
              {data.map((p, i) => (
                <div key={i} className={`col-12 md:col-${p.col}`}>
                  <Image src={p.src} />
                </div>
              ))}
            </div>
          </Container>
        </div>
        <div className="col-12 md:col-6 box2">
          <Container>
            <h5>Blockchain Ecosystem</h5>
            <h2>
              In our blockchain ecosystem
              <br /> everyone extracts value.
            </h2>
            <Image src="/assets/images/01.png" className="mt-5 pt-5" />
          </Container>
        </div>
      </div>
    </Section>
  );
};

export default WhyNow;
