import { Container } from 'components/container';
import data from './data.json';
import { Section } from './index.styled';
import parse from 'html-react-parser';

const Intro = () => {
  return (
    <Section>
      <Container>
        {data.map((item, i) => (
          <div className="grid" key={i}>
            <div className="col-12 md:col-3">
              <h4>{item.label}</h4>
            </div>
            <div className="col-12 md:col-8 md:col-offset-1">
              <>{parse(item.text)}</>
            </div>
          </div>
        ))}
      </Container>
    </Section>
  );
};

export default Intro;
