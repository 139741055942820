import styled from 'styled-components';

interface Props {
  top?: boolean;
  right?: boolean;
}
export const LineDividerWrapper = styled.div<Props>`
  position: absolute;
  left: ${({ right = false }) => (right ? 'unset' : 0)};
  right: ${({ right = false }) => (right ? 0 : 'unset')};
  bottom: ${({ top = false }) => (top ? 'unset' : 0)};
  top: ${({ top = false }) => (top ? 0 : 'unset')};
  height: 1px;
  width: 65%;
  background: linear-gradient(to right, var(--info-color), var(--help-color), var(--danger-color));
`;

export const LineDivider = ({ top = false, right = false }: Props) => {
  return <LineDividerWrapper className="line-divider" top={top} right={right} />;
};
