import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import { clearInterval } from 'timers';
import { Wrapper } from './index.styled';

interface Props {
  label?: string;
}

const PreLoading = ({ label = 'Welcome' }: Props) => {
  const [isProgressDone, setIsProgressDone] = useState(false);
  const [progress, setProgress] = useState(0);
  let isRunning = true;

  useEffect(() => {
    const progressInterval = setInterval(() => {
      if (isRunning) {
        setProgress(prevProgress => {
          if (prevProgress === 400) isRunning = false;
          return prevProgress + 10;
        });
      }
    }, 10);

    return () => {
      isRunning = false;
      if (progressInterval) clearInterval(progressInterval);
    };
  }, []);

  useEffect(() => {
    // console.log('isProgressDone', isProgressDone, progress);
    if (progress === 400 && !isProgressDone) {
      setIsProgressDone(true);
      // console.log('progress', progress);
    }
  }, [progress]);

  return (
    <Wrapper className={classNames('pre-loading', { isdone: isProgressDone })}>
      <div className="loading">
        {Array.from(label).map((c, i) => (
          <span key={i}>{c}</span>
        ))}
      </div>
      <div className={classNames('progress', { 'progress-done': isProgressDone })}>
        <div className="progress-bar" style={{ maxWidth: `${progress}px` }} />
      </div>
    </Wrapper>
  );
};

export default PreLoading;
