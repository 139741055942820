import { Container } from 'components/container';
import { Section } from './index.styled';
import Heading from '../heading';
import { Image } from 'primereact/image';
import { Avatar } from 'primereact/avatar';
import { ProgressBar } from 'primereact/progressbar';

const Crwdid = () => {
  return (
    <Section>
      <Container>
        <Heading text="MARKET VALIDATION | DECENTRALIZED IDENTITY" />

        <div className="grid main">
          <div className="col-12 md:col-7">
            <h4 className="title">Returning value to you</h4>
            <p className="mt-5">
              Claim authority over your digital identity. CrowdPoint has made navigating blockchain simple. The world is
              changing, and open data will change the future of business. By making data portable and giving users full
              control over how they use it, we make room for more innovation and creativity. Most importantly, we return
              the value of data back to the rightful owner.
            </p>
            <q>
              <p>
                93% OF AMERICANS CONSIDERED IT IMPORTANT TO BE ABLE TO CONTROL WHO COULD ACCESS THEIR PERSONAL DATA.
              </p>
              <p>86% OF US CITIZENS HAVE ATTEMPTED TO SOMEHOW REMOVE OR DECREASE THEIR DIGITAL FOOTPRINT ONLINE.</p>
              <p>AROUND 81% OF AMERICANS EXPRESS CONCERNS REGARDING COMPANIES COLLECTING PRIVATE DATA.</p>
            </q>
          </div>
          <div className="col-12 md:col-5">
            <div className="box">
              <div className="grid justify-content-between m-0">
                <h4 className="crwd-terms">
                  crw<b>did:</b>
                </h4>
                <Avatar image="/assets/images/team/photo-1494790108377-be9c29b29330.jpg" size="xlarge" shape="circle" />
              </div>
              <p className="mono">A1DF67BAA874F93F6D1455165D6E037F0BD75191D2 818D83A6030EFE4B0EF6A5</p>
              <h5>A user’s participation gives them performance, protection, and privacy.</h5>
              <div className="grid">
                <div className="col-6">
                  <div className="badge-box">
                    <h2>22</h2>
                    <p className="mono">Badges</p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="badge-box">
                    <h2>240</h2>
                    <p className="mono">Surveys Completes</p>
                  </div>
                </div>
              </div>
              <ProgressBar value={50}></ProgressBar>
              <div className="grid justify-content-between mt-2 mx-0">
                <p className="mono">Identity Data Optimization</p>
                <p className="mono">70%</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Crwdid;
