import { Item, Section } from './index.styled';
import { Container } from 'components/container';
import RotateSVG from 'components/RotateSVG';
import data from './data.json';
import { GradiantText } from 'components/text';

const ItemTemplate = ({ label, text }) => {
  return (
    <Item>
      <div>
        <i className="pi pi-mobile" />
      </div>
      <div className="details">
        <h6>{label}</h6>
        <p>{text}</p>
      </div>
    </Item>
  );
};

const Services = () => {
  return (
    <Section>
      <Container fluid>
        <div className="grid">
          <div className="col-12 md:col-8 md:col-offset-2 sec-head">
            <h6>WHY DO I NEED BLOCKCHAIN POWERED WEBSITE?</h6>
            <GradiantText>There are over a Billion people on the blockchain today.</GradiantText>
          </div>
        </div>
        <div className="grid m-0">
          {data.map((item, i) => (
            <div key={i} className="col-12 md:col-6">
              <ItemTemplate {...item} />
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default Services;
