import styled, { keyframes } from 'styled-components';

export const Section = styled.section`
  padding: 120px 0;
  background: var(--surface-section);
  overflow: hidden;
  position: relative;

  .sec-head {
    position: relative;
    margin: 0 auto 80px;

    h6 {
      font-weight: 300;
      font-size: 17px;
      text-transform: uppercase;
      letter-spacing: 7px;
      margin-bottom: 10px;
      text-align: center;
    }

    h3 {
      text-align: center;
      font-size: 48px;
      font-weight: 500;
    }
  }

  @media (max-width: 576px) {
    .sec-head {
      h6 {
        font-size: 15px;
        letter-spacing: 5px;
      }

      h3 {
        font-size: 20px;
      }
    }
  }
`;

export const Item = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0px 10px 30px;
  padding: 80px 30px;
  border: 1px solid rgba(255, 255, 255, 0.05);

  .icon {
    position: relative;
    width: 80px;
    height: 80px;
    line-height: 80px;
    font-size: 40px;
    margin-bottom: 30px;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: linear-gradient(to right, var(--info-color), var(--help-color), var(--danger-color));
      opacity: 0.05;
    }
  }

  .details {
    padding-left: 30px;

    h6 {
      line-height: 1.8;
      text-transform: capitalize;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 18px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 2;
      margin: 0;
    }
  }
`;
