import styled from 'styled-components';

interface Props {
  fluid?: boolean;
}
export const Container = styled.div<Props>`
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 768px) {
    max-width: ${({ fluid = false }) => (fluid ? 'unset' : '720px')};
    padding-right: 24px;
    padding-left: 24px;
  }
  @media (min-width: 992px) {
    max-width: ${({ fluid = false }) => (fluid ? 'unset' : '960px')};
    padding-right: 24px;
    padding-left: 24px;
  }
  @media (min-width: 1200px) {
    max-width: ${({ fluid = false }) => (fluid ? 'unset' : '1200px')};
    padding-right: 24px;
    padding-left: 24px;
  }
`;
