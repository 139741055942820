import { Container } from 'components/container';
import { Image } from 'primereact/image';
import { Section } from './index.styled';
import data from './data.json';
import Heading from '../heading';

const Foundation = () => {
  return (
    <Section>
      <Container>
        <Heading text="FIX THE FOUNDATION" textColor="dark" />
        <div className="wrap-box">
          <h2 className="title mt-5 pt-3 mb-5">
            Introducing the world's first decentralized cryptographic cloud platform
          </h2>
          <h4 className="subtitle mb-5">
            Purpose built to be a fully integrated, end-to-end solution for the market. Boundless scale, fault-tolerant,
            and enterprise-grade database speeds to give the world truly secure computing.
          </h4>

          <ul className="mb-5">
            {data.map((v, i) => (
              <li key={i}>{v}</li>
            ))}
          </ul>
        </div>
        <Image src="/assets/images/team/Image_009.png" width="100%" />
      </Container>
    </Section>
  );
};

export default Foundation;
