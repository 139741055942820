import { Image } from 'primereact/image';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: inline-flex;

  .p-image {
    img {
      filter: brightness(110%) contrast(90%) saturate(150%);
    }

    &.right {
      margin-left: -25px;
      transform: translate(0px, 0px) rotate(0deg) scaleX(-1);
    }
  }
`;

// Or specifically as JSX.Element
const ImageElement = Image as unknown as (props: any) => JSX.Element;

export const ArrowDivider = () => {
  return (
    <Wrapper>
      <ImageElement src="/assets/images/team/long-arrow-left.svg" className="left" />
      <ImageElement src="/assets/images/team/long-arrow-left.svg" className="right" />
    </Wrapper>
  );
};
