import { Item, Section, SPAN } from './index.styled';
import { Container } from 'components/container';
import RotateSVG from 'components/RotateSVG';
import data from './data.json';
import { GradiantText } from 'components/text';
import { Button } from 'primereact/button';

const ItemTemplate = ({ img, url }) => {
  return (
    <Item className="p-button-text p-button-plain p-button-outlined">
      <img src={img} />
      <h6>
        {Array.from(url).map((c, i) => (
          <SPAN key={i} index={i}>
            {c}
          </SPAN>
        ))}
      </h6>
    </Item>
  );
};

const Clients = () => {
  return (
    <Section>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-4 sec-head">
            <h6>CRWDWORLD</h6>
            <GradiantText>UNIVERSAL PERFORMANCE</GradiantText>
            <p>We are the automated content factory for crwdworld. We provide online performance for your business.</p>
          </div>
          <div className="col-12 md:col-8">
            <div className="grid">
              {data.map((item, i) => (
                <div key={i} className="col-6 md:col-3">
                  <ItemTemplate {...item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Clients;
