import styled, { keyframes } from 'styled-components';

export const Section = styled.section`
  padding: 120px 0;
  background: var(--surface-section);
  overflow: hidden;
  position: relative;

  .sec-head {
    position: relative;
    margin: 0 auto 80px;

    h6 {
      font-weight: 300;
      font-size: 17px;
      text-transform: uppercase;
      letter-spacing: 7px;
      margin-bottom: 10px;
      text-align: center;
    }

    h3 {
      text-align: center;
      font-size: 48px;
      font-weight: 500;
    }
  }

  .p-tabmenu {
    padding: 15px;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 30px;
    display: inline-block;

    .p-tabmenu-nav {
      background: transparent;
      border-width: 0;

      .p-tabmenuitem {
        .p-menuitem-link {
          font-weight: 500;
          font-size: 12px;
          letter-spacing: 1px;
          text-transform: uppercase;
          background: transparent;
          border-color: transparent;
          color: var(--text-color);
          padding: 0 20px;

          &:after {
            content: '';
            width: 7px;
            height: 7px;
            background: var(--primary-color);
            border-radius: 50%;
            position: absolute;
            right: 0px;
            top: 20%;
          }

          &:hover {
            background: transparent !important;
            border-color: transparent !important;
            color: var(--primary-color) !important;
          }
        }

        &:nth-child(4) {
          .p-menuitem-link {
            &:after {
              display: none;
            }
          }
        }

        &.p-highlight {
          .p-menuitem-link {
            background: transparent;
            border-color: transparent;
            color: var(--primary-color);
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    .sec-head {
      h6 {
        font-size: 15px;
        letter-spacing: 5px;
      }

      h3 {
        font-size: 20px;
      }
    }

    .p-tabmenu {
      max-width: 100%;
      padding: 15px 0;

      .p-tabmenuitem {
        .p-menuitem-link {
          padding: 0 15px 0 10px !important;
        }
      }
    }
  }
`;

export const Item = styled.div`
  padding: 0 50px;
  margin: 50px 0;
  height: 600px;

  &.big {
    margin-top: 100px;
  }

  .item-img {
    overflow: hidden;
    position: relative;
    clip-path: inset(0);
    transition: all 0.3s;

    &:hover {
      clip-path: inset(5px 10px);

      img {
        transform: scale(1.05);
      }
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: all 0.5s;
    }
  }

  .cont {
    margin-top: 30px;
    text-align: center;

    h6 {
      color: #999;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 400;
      font-size: 13px;
    }

    h5 {
      font-weight: 600;
      margin-bottom: 5px;
      margin: 0;
      line-height: 1.4;
      font-size: 18px;
    }
  }

  @media (max-width: 576px) {
    padding: 0;
    height: 350px;

    &.big {
      margin-top: 50px;
    }
  }
`;
