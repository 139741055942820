import { Breadcrumbs } from 'components/breadcrumbs';
import PreLoading from 'components/pre-loading';
import CallAction from './call-action';
import Clients from './clients';
import Intro from './intro';
import Services from './services';
import Summary from './summary';
import TeamCRV from './team-crv';
import TestimonialBlock from './testimonial-block';

const AboutPage = () => {
  return (
    <>
      <PreLoading label="ABOUT" />
      <Breadcrumbs label="CONTENT CREATION FOR:" />
      <div className="grid" style={{ marginTop: '-45px', position: 'relative' }}>
        <div className="col-12 md:col-10 md:col-offset-1">
          <img src="/assets/images/about.png" width="100%" />
        </div>
      </div>
      <Intro />
      <Services />
      <TestimonialBlock />
      <TeamCRV />
      <Summary />
      <Clients />
      <CallAction />
    </>
  );
};
export default AboutPage;
