import { Container } from 'components/container';
import { Avatar } from 'primereact/avatar';
import { Section } from './index.styled';
import data from './data.json';
import Heading from '../heading';

const MeetTeam = () => {
  return (
    <Section>
      <Container>
        <Heading text="MEET THE TEAM" lineColor="dark" />
        <h4 className="subtitle mt-5 pt-5 mb-5">OUR MOST VALUABLE ASSETS</h4>
        <h2 className="title mb-5">
          An elite team of successful, proven, and experienced data scientists, technology entrepreneurs, financiers &
          business executives
        </h2>
      </Container>
      <div className="box">
        <Container>
          <div className="grid">
            <div className="col-12 md:col-7">
              <div className="inner-box">
                <p className="des text-center">SENIOR MANAGEMENT TEAM</p>
                <div className="grid my-5">
                  {data.map((m, i) => (
                    <div key={i} className="col-12 md:col-4">
                      <div className="grid card">
                        <div className="col-4">
                          <Avatar image={m.img} shape="circle" size="xlarge" />
                        </div>
                        <div className="col-8">
                          <h5>{m.name}</h5>
                          <p className="des">{m.detail}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-12 md:col-5 pl-5">
              <p className="des">KEY STRATEGIC ADVISORS</p>
              <ul>
                <li>MG Dave Scott, Ret</li>
                <li>Lorraine Spurge</li>
                <li>Edwin Wang</li>
                <li>Sophia Yao</li>
              </ul>
              <p className="des">
                WITH OVER 150 YEARS OF XPERIENCE IN ACCESS TO APITAL, EXECUTIVE LEADERSHIP,CYBER SECURITY AND NATIONAL
                INTELLIGENCE COMMAND, INVESTMENT MANAGEMENT,VENTURE CAPITAL,PRIVATE EQUITY AND MENTORING OF TECHNOLOGY
                AND INNOVATION UNICORNS.
              </p>
            </div>
          </div>
        </Container>
      </div>
    </Section>
  );
};

export default MeetTeam;
