import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MenuBar } from './index.styled';
import { MenuItem } from 'primereact/menuitem';
import { useEffect, useState } from 'react';

const Header = () => {
  const navigate = useNavigate();

  // Sticky Menu Area
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', stickyCallBak);

    return () => {
      window.removeEventListener('scroll', stickyCallBak);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const stickyCallBak = () => {
    const scrollTop = window.scrollY;
    scrollTop >= 200 ? setIsSticky(true) : setIsSticky(false);
  };

  const items: Array<MenuItem> = [
    { label: 'Home', command: () => navigate('/') },
    {
      label: 'About',
      command: () => navigate('/about'),
    },
    { label: 'Contact', command: () => navigate('/contact') },
    { label: 'Login', url: 'https://app.crwd.shop/' },
    // { label: 'Team', command: () => navigate('/team') },
  ];

  const start = (
    <Link to="/">
      <img alt="logo" src="/assets/images/logo-light.png" className="mr-2" />
    </Link>
  );

  return (
    <>
      <MenuBar model={items} start={start} className={`${isSticky ? 'header fixed' : 'header'}`} />
    </>
  );
};

export default Header;
