import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  padding: 80px 0 20px;

  .wrap-box {
    width: 80%;
  }

  .title {
    font-size: 40px;
    font-weight: 700;
  }

  .subtitle {
    font-weight: 600;
    text-transform: initial;
  }

  ul {
    padding-left: 22px;

    li {
      font-size: 18px;
      font-weight: 400;
    }
  }

  @media (max-width: 576px) {
  }
`;
