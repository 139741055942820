import styled from 'styled-components';

export const Section = styled.section`
  .box1,
  .box2 {
    position: relative;
    padding: 50px 0;
  }

  .box1 {
    background: #fff;
  }

  .box2 {
    h5 {
      margin-top: 110px;
    }
    h2 {
      color: #fff;
    }
  }

  h5 {
    font-size: 1.5em;
    color: var(--p-color);
    font-weight: 500;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 80px;
  }

  h2 {
    font-size: 2em;
    color: var(--s-color);
    font-weight: 900;
    line-height: 1;
    margin-top: 30px;
    text-align: center;
  }

  .p-image {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 0 20px;
    margin-bottom: 20px;

    img {
      max-width: 100%;
    }
  }

  @media (max-width: 576px) {
  }
`;
