import styled, { keyframes } from 'styled-components';

export const Section = styled.section`
  padding: 120px 0;
  background: var(--surface-section);
  overflow: hidden;
  position: relative;

  .sec-head {
    position: relative;
    margin: 0 auto 80px;

    h6 {
      font-weight: 300;
      font-size: 17px;
      text-transform: uppercase;
      letter-spacing: 7px;
      margin-bottom: 10px;
      text-align: center;
    }

    h3 {
      text-align: center;
      font-size: 48px;
      font-weight: 500;
    }
  }

  input {
    width: 100%;
  }

  @media (max-width: 576px) {
    .sec-head {
      h6 {
        font-size: 15px;
        letter-spacing: 5px;
      }

      h3 {
        font-size: 20px;
      }
    }
  }
`;
