import { Item, Section, Progress } from './index.styled';
import { Container } from 'components/container';
import RotateSVG from 'components/RotateSVG';
import data from './data.json';
import { BGTitleText } from 'components/text';
import { ProgressBar } from 'primereact/progressbar';
import VisibilitySensor from 'react-visibility-sensor';
import { CountUp } from 'use-count-up';

const CustomProgressBar = ({ end = 0, label, className = '' }) => {
  return (
    <CountUp isCounting end={end}>
      {({ value, reset }) => (
        <VisibilitySensor onChange={reset}>
          <Progress value={value as number} className={className}>
            <h6>{label}</h6>
            <ProgressBar value={value as number} />
          </Progress>
        </VisibilitySensor>
      )}
    </CountUp>
  );
};

const ItemTemplate = ({ img }) => {
  return (
    <Item>
      <img src={img} alt="online contact card" />
    </Item>
  );
};

const TeamCRV = () => {
  return (
    <Section>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-5 sec-head">
            <BGTitleText>OUR TEAM</BGTitleText>
            <h3>Our visual strategies improve sales.</h3>
            <p>
              We are crwdshop. We deploy award-winning techniques that maximize viewer retention, recall, application
              and transfer of your content through cutting-edge technology.
            </p>
            <CustomProgressBar end={65} label="CUSTOMER DESIGNED AND BUILT" />
            <CustomProgressBar end={24} label="CRWDSHOP ASSISTED" />
            <CustomProgressBar end={21} label="CRWDSHOP DESIGN AND BUILT" className="m-0" />
          </div>
          <div className="col-12 md:col-6 md:col-offset-1 ">
            <div className="grid m-0">
              {data.map((item, i) => (
                <div key={i} className="col-12 md:col-6 p-0">
                  <ItemTemplate {...item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default TeamCRV;
