import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  padding: 80px 0;
  background-image: linear-gradient(220deg, rgb(83, 118, 241), rgb(181, 15, 174));

  .title {
    font-weight: 800;
    font-size: 55px;
    letter-spacing: -3px;
    color: var(--s-color);
  }

  .grid.main {
    margin-top: 100px;
  }

  .box {
    background: #fff;
    border-radius: 40px;
    padding: 20px 40px;
    max-width: 450px;
    width: 100%;
    margin: 0 0 0 auto;

    h4 {
      color: #212121;
      font-size: 50px;
    }

    .p-avatar {
      width: 11rem;
      height: 11rem;
      margin-top: -7.5rem;

      img {
        filter: sepia(30%) brightness(110%) contrast(110%) grayscale(100%);
      }
    }

    .id {
    }

    h5 {
      color: var(--p-color);
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 30px;
    }

    .mono {
      color: #212121;
      font-size: 13px;
      line-height: 21px;
      margin-top: 0;
      font-family: 'DM Mono', monospace;
      text-transform: none;
      font-weight: 400;
      letter-spacing: 0.02em;
      font-style: normal;
    }

    .badge-box {
      background: var(--s-color);
      text-align: center;
      padding: 30px 10px 10px;
      border-radius: 15px;

      h2 {
        line-height: 1;
        font-size: 30px;
      }

      p {
        margin: 0;
        color: #fff;
      }
    }

    .p-progressbar {
      margin-top: 30px;
      height: 11px;

      .p-progressbar-value {
        background: var(--p-color);
      }
    }
  }

  p {
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 28px;
  }

  q {
    position: relative;
    padding: 0 65px;
    display: block;
    margin-top: 80px;

    p {
      color: var(--s-color);
      font-size: 15px;
      font-weight: 800;
      letter-spacing: 0.02em;
      line-height: 1.4;
      word-spacing: 5px;
      font-family: 'DM Mono', monospace;
      text-shadow: 0px -2px 1px rgba(86, 37, 153, 0.55);
      opacity: 0.8;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 45px;
      height: 45px;
    }

    &:before {
      background-image: url('/assets/images/team/quote-left.svg');
      top: -20px;
      left: 0px;
    }

    &:after {
      background-image: url('/assets/images/team/quote-right.svg');
      bottom: -10px;
      right: 0px;
    }
  }

  @media (max-width: 576px) {
  }
`;
