import styled from 'styled-components';

export const Section = styled.section`
  position: relative;

  .overlay {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    height: calc(100% - 50px);
  }

  .p-image {
    display: flex;
    width: 100%;
    height: auto;

    img {
      width: 100%;
    }
  }

  @media (max-width: 576px) {
  }
`;
