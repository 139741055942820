import { Container } from 'components/container';
import SocialLinks from 'components/social';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FooterWrapper } from './index.styled';

const Footer = () => {
  return (
    <FooterWrapper>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-4 contact">
            <h2>Contact us</h2>

            <ul>
              <li>
                <i className="pi pi-map-marker" />
                <div className="cont">
                  <h6>Austin Office</h6>
                  <p>510815 Ranch Rd 2222, Austin, TX 78730, USA</p>
                </div>
              </li>
              <li>
                <i className="pi pi-envelope" />
                <div className="cont">
                  <h6>Email Us</h6>
                  <p>support@crwd.id</p>
                </div>
              </li>
              <li>
                <i className="pi pi-phone" />
                <div className="cont">
                  <h6>Call Us</h6>
                  <p>+1 512 767 1773</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-12 md:col-4 explore">
            <h2>Explore our crwdworld</h2>

            <ul>
              <li>
                <div className="img">
                  <img src="/assets/images/crwdmarket_crwdid.png" alt="" />
                </div>
                <div className="post">
                  <p>crwdmarket</p>
                  <Button
                    label="Only on crwdworld"
                    className="p-button-link p-0"
                    onClick={() => window.open('https://crwd.world/about', '_blank')}
                  />
                </div>
              </li>
              <li>
                <div className="img">
                  <img src="/assets/images/crwdcapital_crwdid.png" alt="" />
                </div>
                <div className="post">
                  <p>crwdcapital</p>
                  <Button
                    label="Only on crwdworld"
                    className="p-button-link p-0"
                    onClick={() => window.open('https://crwd.world/about', '_blank')}
                  />
                </div>
              </li>
              <li>
                <div className="img">
                  <img src="/assets/images/crwdfinance_crwdid.png" alt="" />
                </div>
                <div className="post">
                  <p>crwdfinances</p>
                  <Button
                    label="Only on crwdworld"
                    className="p-button-link p-0"
                    onClick={() => window.open('https://crwd.world/about', '_blank')}
                  />
                </div>
              </li>
              <li>
                <div className="subscribe">
                  <InputText name="email" placeholder="Type Your Email" />
                  <Button icon="pi pi-send" className="p-button-rounded" />
                </div>
              </li>
            </ul>
          </div>
          <div className="col-12 md:col-4 copy-right">
            <img src="/assets/images/logo-light.png" />
            <SocialLinks
              links={{
                fb: '#',
                twitter: '#',
                instagram: '#',
                youtube: '#',
              }}
            />
            <p>
              © 2024, CRWDID: YOUR PASSPORT TO:
              <Button label="CRWDWORLD" className="p-button-link p-0" />.
            </p>
          </div>
        </div>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
