import Button from 'components/button';
import { Container } from 'components/container';
import { BGTitleText } from 'components/text';
import { ParallaxBanner } from './index.styled';

const CallAction = () => {
  return (
    <ParallaxBanner
      layers={[
        {
          image: '/assets/images/patrn.svg',
          speed: 30,
        },
      ]}
    >
      <Container>
        <div className="grid m-0 align-items-center">
          <div className="col-12 md:col-9">
            <h5>TAKE ACTION :</h5>
            <label>
              BUILD YOUR
              <br />
              <BGTitleText>ONLINE PRESENCE</BGTitleText> TODAY.
            </label>
          </div>

          <div className="col-12 md:col-3">
            <Button
              label="BUILD YOUR DESTINY TODAY"
              className="p-button-outlined light"
              onClick={() => window.open('https://app.crwd.shop/', '_blank')}
            />
          </div>
        </div>
      </Container>
    </ParallaxBanner>
  );
};

export default CallAction;
