import styled from 'styled-components';

interface Props {
  text?: string;
  lineColor?: 'light' | 'dark';
  textColor?: 'light' | 'dark';
}
const H6 = styled.h6<Props>`
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  margin-bottom: 9px;
  font-family: 'DM Mono', monospace;
  color: ${({ textColor = 'light' }) => (textColor === 'light' ? '#fff' : '#7e3adb')};
  position: relative;

  &:before {
    content: '';
    width: 100px;
    height: 2px;
    background: ${({ lineColor = 'light' }) => (lineColor === 'light' ? '#fff' : '#7e3adb')};
    margin-bottom: 5px;
    display: block;
  }
`;

const Heading = ({ text, lineColor, textColor }: Props) => {
  return (
    <H6 lineColor={lineColor} textColor={textColor}>
      {text}
    </H6>
  );
};

export default Heading;
