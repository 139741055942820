import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  background: #fff;
  padding: 80px 0 20px;

  .wrap-box {
    width: 80%;
  }

  .title {
    font-size: 40px;
    font-weight: 700;
  }

  .subtitle {
    font-weight: 600;
    text-transform: initial;
  }

  .p-image {
    display: block;
    width: 100%;
    height: auto;
    padding: 0 10px;

    &.center {
      text-align: center;
    }
  }

  @media (max-width: 576px) {
  }
`;
