import { Section, Item } from './index.styled';
import data from './data.json';
import parse from 'html-react-parser';
import AwesomeSlider, { AwesomeSliderInfo } from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/scale-out-animation.css';
import Button from 'components/button';
import { GradiantText } from 'components/text';
import { useState } from 'react';
import SocialLinks from 'components/social';
import { Parallax } from 'react-scroll-parallax';
import { Link } from 'react-scroll';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const ItemTemplate = ({ img, label, text, action }) => (
  <Item>
    <Parallax speed={5} className="grid">
      <GradiantText>{parse(label)}</GradiantText>
      <p>{text}</p>
      {action.path ? (
        <Button
          label={action.label}
          className="p-button-outlined light"
          onClick={() => window.open(action.path, '_blank')}
        />
      ) : (
        <Link to={action.to} smooth={true} offset={0} duration={1000}>
          <Button label={action.label} className="p-button-outlined light" />
        </Link>
      )}
    </Parallax>
  </Item>
);

const BannerSlider = () => {
  const [cSlide, setCSlide] = useState(0);
  const [totalSlide, setTotalSlide] = useState(0);

  return (
    <Section>
      <AutoplaySlider
        name="slider"
        play={true}
        cancelOnInteraction={true} // should stop playing on user interaction
        interval={6000}
        animation="scaleOutAnimation"
        fillParent={true}
        onFirstMount={(e: AwesomeSliderInfo) => {
          setCSlide(e.currentIndex);
          setTotalSlide(e.slides);
        }}
        onTransitionEnd={(e: AwesomeSliderInfo) => {
          setCSlide(e.currentIndex);
          setTotalSlide(e.slides);
        }}
      >
        {data.map((item, i) => (
          <div key={i} data-src={item.img}>
            <ItemTemplate {...item} />
          </div>
        ))}
      </AutoplaySlider>
      <div className="pagination">
        <span className="current">{cSlide}</span> <span className="total">{totalSlide}</span>
      </div>
      <div className="social-wrapper">
        <SocialLinks className="social" />
      </div>
    </Section>
  );
};

export default BannerSlider;
