import { Item, Section, Progress } from './index.styled';
import { Container } from 'components/container';
import RotateSVG from 'components/RotateSVG';
import data from './data.json';
import { BGTitleText } from 'components/text';
import { ProgressBar } from 'primereact/progressbar';
import VisibilitySensor from 'react-visibility-sensor';
import { CountUp } from 'use-count-up';

const CustomProgressBar = ({ end = 0, label, className = '' }) => {
  return (
    <CountUp isCounting end={end}>
      {({ value, reset }) => (
        <VisibilitySensor onChange={reset}>
          <Progress value={value as number} className={className}>
            <h6>{label}</h6>
            <ProgressBar value={value as number} />
          </Progress>
        </VisibilitySensor>
      )}
    </CountUp>
  );
};

const ItemTemplate = ({ img }) => {
  return (
    <Item>
      <img src={img} alt="online contact card" />
    </Item>
  );
};

const TeamCRV = () => {
  return (
    <Section>
      <Container>
        <div className="grid m-0">
          <div className="col-12 md:col-5 sec-head">
            <BGTitleText>OPEN FOR BUSINESS</BGTitleText>
            <h3>
              In our crwdworld blockchain ecosystem, crwdshop is committed to preparing you for meeting billions of
              crwdid's.
            </h3>

            <CustomProgressBar end={100} label="THE CRWDID AS A CONSUMER" />
            <CustomProgressBar end={100} label="HE CRWDID AS AN INVESTOR" />
            <CustomProgressBar end={100} label="HE CRWDID AS A COMPANY MEMBER" className="m-0" />
          </div>
          <div className="col-12 md:col-6 md:col-offset-1 ">
            <div className="grid m-0">
              {data.map((item, i) => (
                <div key={i} className="col-12 md:col-6 p-0">
                  <ItemTemplate {...item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default TeamCRV;
