import { ProgressBar as PB } from 'primereact/progressbar';
import styled, { keyframes } from 'styled-components';

interface Props {
  value: number;
}
export const Progress = styled.div<Props>`
  margin-bottom: 50px;

  h6 {
    font-size: 13px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 10px !important;
    line-height: 1.4;
  }

  .p-progressbar {
    height: 7px;
    overflow: visible;

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 8px solid #151424;
      position: absolute;
      left: ${({ value }) => `${value}%`};
      bottom: 12px;
      transition: left 0.2s ease-in-out;
    }

    &:after {
      content: ${({ value }) => `'${value}%'`};
      position: absolute;
      left: ${({ value }) => `${value - 3}%`};
      top: -45px;
      padding: 7px 15px;
      border-radius: 5px;
      background: #151424;
      font-size: 12px;
      font-weight: 600;
      transition: left 0.2s ease-in-out;
    }

    .p-progressbar-value-animate {
      transition: left 0.2s ease-in-out;
    }

    .p-progressbar-value {
      background: linear-gradient(to right, var(--info-color), var(--help-color), var(--danger-color));
    }
  }
`;

export const Section = styled.section`
  padding: 120px 0;
  // background: var(--surface-section);
  overflow: hidden;

  .sec-head {
    position: relative;

    h6 {
      margin-bottom: 20px;
    }

    h3 {
      margin: 0;
      line-height: 1.4;
      font-weight: 500;
      font-size: 35px;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 40px;
    }
  }

  @media (max-width: 576px) {
  }
`;

export const Item = styled.div`
  padding: 10px 10px 0;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 576px) {
    padding: 10px 0px 0;
  }
`;
