import { Container } from 'components/container';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import Slider, { Settings } from 'react-slick';
import { Item, ParallaxBanner, Section, SPAN, ClientItem } from './index.styled';
import data from './data.json';
import clientsData from './clients.json';
import parse from 'html-react-parser';
import PlayerDialog from 'components/player-modal';
import { useState } from 'react';

interface Props {
  author: { img: string; name: string; detail: string };
  text: string;
}

const ItemTemplate = (props: Props) => (
  <Item>
    <p>{parse(props.text)}</p>
    <div className="info">
      <div className="img-box">
        <Avatar image={props.author.img} shape="circle" size="xlarge" />
      </div>
      <div className="author">
        <h6 className="author-name ">{props.author.name}</h6>
        <span className="author-details">{props.author.detail}</span>
      </div>
    </div>
  </Item>
);

const ClientTemplate = ({ img, url }) => {
  return (
    <ClientItem className="p-button-text p-button-plain p-button-outlined">
      <img src={img} />
      <h6>
        {Array.from(url).map((c, i) => (
          <SPAN key={i} index={i}>
            {c}
          </SPAN>
        ))}
      </h6>
    </ClientItem>
  );
};

const TestimonialBlock = () => {
  const [show, setShow] = useState(false);

  var settings = {
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  } as Settings;

  return (
    <Section>
      <ParallaxBanner
        layers={[
          {
            image: '/assets/images/bg-vid-2.jpg',
            speed: -20,
          },
        ]}
      >
        <Container>
          <div className="vid-area">
            <span className="text">Watch Video</span>
            <Button
              icon="pi pi-play"
              className="p-button-rounded p-button-secondary p-button-outlined"
              onClick={() => setShow(true)}
            />
            <PlayerDialog show={show} onHide={() => setShow(false)} videoUrl="6306709017112" />
          </div>
        </Container>
      </ParallaxBanner>
      <div className="testim-box">
        <div className="head-box">
          <h6>OUR CLIENTS ROCK!</h6>
          <h4>WHAT DO THEY SAY?</h4>
        </div>
        <Slider {...settings}>
          {data.map((item, i) => (
            <ItemTemplate key={i} {...item} />
          ))}
        </Slider>
      </div>
      <div className="client-wrapper">
        <Container>
          <div className="grid">
            <div className="col-12 md:col-6">
              <div className="grid">
                {clientsData.map((item, i) => (
                  <div key={i} className="col-12 md:col-3">
                    <ClientTemplate {...item} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Section>
  );
};

export default TestimonialBlock;
