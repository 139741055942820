import PreLoading from 'components/pre-loading';
import BusinessModal from './business-modal';
import Crwdid from './crwdid';
import Foundation from './foundation';
import Growth from './growth';
import './index.styled.css';
import MeetTeam from './meet-team';
import NewEra from './new-era';
import Partners from './partners';
import WhyNow from './why-now';

const TeamPage = () => {
  return (
    <>
      <PreLoading label="WELCOME TO TEAM" />
      <MeetTeam />
      <Foundation />
      <Partners />
      <Growth />
      <BusinessModal />
      <NewEra />
      <Crwdid />
      <WhyNow />
    </>
  );
};

export default TeamPage;
