import { Section } from './index.styled';
import { Container } from 'components/container';
import { GradiantText } from 'components/text';
import { LineDivider } from 'components/LineDivider';
import { InputText } from 'primereact/inputtext';
import Button from 'components/button';

const Enroll = () => {
  return (
    <Section>
      <LineDivider />
      <Container>
        <div className="grid">
          <div className="col-12 md:col-8 md:col-offset-2 sec-head">
            <h6>JOIN TODAY</h6>
            <GradiantText>
              Complete this form and make
              <br /> magic together.
            </GradiantText>
          </div>
        </div>
        <div className="grid">
          <div className="col-12 md:col-10 md:col-offset-1">
            <div className="grid m-0">
              <div className="col-12 md:col-4">
                <InputText name="fName" placeholder="FIRST NAME" className="mb-3" />
              </div>
              <div className="col-12 md:col-4">
                <InputText name="lName" placeholder="LAST NAME" className="mb-3" />
              </div>
              <div className="col-12 md:col-4">
                <InputText name="phone" placeholder="PHONE" className="mb-3" />
              </div>
              <div className="col-12 md:col-4">
                <InputText name="password" placeholder="PASSWORD" className="mb-3" />
              </div>
              <div className="col-12 md:col-4">
                <InputText name="email" placeholder="EMAIL" className="mb-3" />
              </div>
              <div className="col-12 md:col-12 text-center mt-5">
                <Button label="ENROLL NOW" className="light p-button-outlined" />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Enroll;
