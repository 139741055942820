import styled from 'styled-components';

export const Section = styled.section`
  padding: 120px 0;
  background: var(--surface-section);

  .head {
    h6 {
      display: inline-block;
      margin-bottom: 20px;
    }

    h3 {
      line-height: 1.4;
      font-size: 35px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
      line-height: 2;
      margin: 0;
    }
  }

  img {
    width: 100%;
  }

  .ftbox {
    margin: 0;
    display: flex;
    list-style: none;
    padding: 0;
    gap: 10px;
    margin-top: 40px;

    li {
      width: 100%;
      padding: 35px 25px;
      text-align: center;
      background: #212329;
      border-radius: 5px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon {
        font-size: 30px;
        margin-bottom: 20px;
        background: linear-gradient(to right, var(--info-color), var(--help-color), var(--danger-color));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      h6 {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        line-height: 1.4;
      }
      .dots {
        span {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: #eee;
          position: absolute;
          right: 10px;
          bottom: 12px;
          opacity: 0.1;

          &:first-of-type {
            bottom: 18px;
          }

          &:last-of-type {
            right: 16px;
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    .ftbox {
      h6 {
        font-size: 12px !important;
      }
    }
  }
`;
