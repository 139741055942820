import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  position: relative;
  background: #212329;
  padding: 100px 0;
  overflow: hidden;

  h2 {
    margin: 0;
    line-height: 1.4;
    font-weight: 500;
    margin-bottom: 30px;
    font-size: 25px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      margin-bottom: 25px;
    }
  }

  .contact {
    ul {
      li {
        i {
          font-size: 30px;
          margin-right: 30px;
        }
        .cont {
          h6 {
            margin: 0;
            font-size: 14px;
            font-weight: 600;
          }
          p {
            font-size: 16px;
            font-weight: 300;
            line-height: 2;
            margin: 0;
          }
        }
      }
    }
  }

  .explore {
    ul {
      li {
        .img {
          width: 150px;
          margin-right: 15px;

          img {
            width: 100%;
            height: auto;
          }
        }

        .post {
          p {
            color: var(--text-color);
            font-size: 14px;
            font-weight: 300;
            line-height: 2;
            margin: 0;
          }

          span {
            font-size: 13px;
            color: var(--primary-color);
          }
        }

        .subscribe {
          position: relative;
          width: 100%;

          input {
            border-radius: 30px;
            width: 100%;
          }
          button {
            position: absolute;
            top: 0;
            right: -7px;
            padding: 1.571rem;
          }
        }
      }
    }
  }

  .copy-right {
    img {
      margin-bottom: 30px;
      width: 130px;
    }

    p {
      color: var(--text-color);
      font-size: 13px;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 3px;
      margin-top: 30px;

      button {
        font-size: 13px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 3px;
        line-height: 30px;
      }
    }
  }

  @media (max-width: 576px) {
  }
`;
