import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  padding: 80px 0;
  background-image: linear-gradient(220deg, rgb(83, 118, 241), rgb(181, 15, 174));

  .title {
    font-weight: 800;
    font-size: 36px;
    color: var(--s-color);
    letter-spacing: 0px;
  }

  .overlay {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(200deg, rgba(83, 118, 243, 0.5) 0%, rgba(181, 15, 174, 0.5) 100%);
  }

  .pw {
    display: flex;
    border-radius: 20px;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(200deg, rgba(83, 118, 243, 0.5) 0%, rgba(181, 15, 174, 0.5) 100%);
    }
  }

  .box1 {
    background: #fff;
    border-radius: 20px;
    padding: 10px;
    max-width: 300px;

    h2,
    p {
      color: var(--s-color);
    }
  }

  .box2 {
    border-radius: 20px;
    border: 1px solid #fff;
    padding: 10px;
    width: 100%;
    max-width: 250px;

    h2 {
      color: var(--s-color);
    }

    p {
      color: #fff;
    }
  }

  .des {
    font-family: 'DM Mono', monospace;
    color: var(--s-color);
    font-size: 13px;
    font-weight: 400;
  }

  .timeline {
    p {
      margin: 0;
      color: #fff;
      position: relative;
      padding-top: 25px;

      &:before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 5px;
        background: rgb(104, 75, 255);
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
      }
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 3px;
        background: #fff;
        position: absolute;
        top: 10px;
        z-index: 0;
      }

      &.center {
        text-align: center;

        &:before {
          left: calc(50% - 10px);
        }
      }

      &.right {
        text-align: right;

        &:before {
          left: calc(100% - 20px);
        }
      }
    }
  }

  @media (max-width: 576px) {
  }
`;
