import { Item, Section } from './index.styled';
import { Container } from 'components/container';
import data from './data.json';
import { useParallax } from 'react-scroll-parallax';
import { GradiantText } from 'components/text';

const ItemTemplate = ({ label, text, index }) => {
  return (
    <Item>
      <h6>
        <span>{index}</span> {label}
      </h6>
      <p>{text}</p>
    </Item>
  );
};

const Summary = () => {
  const parallax = useParallax<HTMLDivElement>({
    translateY: [-10, 10],
    speed: -50,
  });
  return (
    <Section>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-6">
            <div className="img-wrapper" ref={parallax.ref}>
              <img src="/assets/images/min-area2.png" />
            </div>
          </div>
          <div className="col-12 md:col-6 content">
            <GradiantText>IN SUMMARY:</GradiantText>
            <p>
              Our vision for a decentralized commerce strategy through crwdmarket was to create an easy to use ONLINE
              website builder for the crwid to be supported by world class professionals to help them along their way.
            </p>
            {data.map((item, i) => (
              <ItemTemplate key={i} {...item} />
            ))}
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Summary;
