import PreLoading from 'components/pre-loading';
import TeamCRV from './team-crv';
import About from './about';
import BannerSlider from './banner';
import Portfolio from './portfolio';
import Services from './services';
import TestimonialBlock from './testimonial-block';
import BlogCRV from './blog-crv';
import Enroll from './enroll';

const HomePage = () => {
  return (
    <>
      <PreLoading label="WELCOME TO crwdshop" />
      <BannerSlider />
      <div style={{ marginTop: '100vh', position: 'relative', zIndex: '1' }}>
        <About />
        <Services />
        <Portfolio />
        <TestimonialBlock />
        <TeamCRV />
        <BlogCRV />
        <Enroll />
      </div>
    </>
  );
};

export default HomePage;
