import { ProgressBar as PB } from 'primereact/progressbar';
import styled, { keyframes } from 'styled-components';

export const Section = styled.section`
  padding: 120px 0 90px;
  margin-bottom: 2px;
  overflow: hidden;
  background: var(--surface-section);

  .img-wrapper {
    width: 100%;
    will-change: transform;
    transition: transform 1s cubic-bezier(0, 0, 0, 1) 0s;

    img {
      width: 100%;
    }
  }

  .content {
    position: relative;

    h3 {
      display: inline-block;
      font-weight: 700;
      font-size: 40px;
      line-height: 1.5;
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 2;
      margin: 0 0 40px;
    }
  }

  @media (max-width: 576px) {
    .content {
      h3 {
        font-size: 20px;
      }
    }
  }
`;

export const Item = styled.div`
  padding: 0 20px 30px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  margin-top: 25px;
  position: relative;

  &:last-of-type {
    padding-bottom: 0;
    border: 0;
  }

  h6 {
    margin: 0;
    line-height: 1.4;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;

    span {
      display: inline-block;
      color: var(--primary-color);
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 13px;
      border: 1px solid var(--primary-color);
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  p {
    font-size: 13px;
    padding-left: 45px;
    font-weight: 300;
    line-height: 2;
    margin: 0 !important;
  }
`;
