import { ParallaxBanner as P } from 'react-scroll-parallax';
import styled, { keyframes } from 'styled-components';

export const Section = styled.section`
  position: relative;

  .testim-box {
    position: absolute;
    bottom: 100px;
    right: 5%;
    padding: 80px 40px;
    z-index: 2;
    max-width: 470px;
    width: 100%;

    &:after {
      content: '';
      position: absolute;
      left: -10px;
      top: -10px;
      right: -10px;
      bottom: -10px;
      background: linear-gradient(to right, var(--info-color), var(--help-color), var(--danger-color));
      z-index: -4;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 0px;
      background: #111215;
      z-index: -1;
    }

    .head-box {
      h6 {
        color: var(--primary-color);
        font-size: 12px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 4px;
        margin-bottom: 10px;
      }

      h4 {
        font-size: 30px;
        line-height: 1.4;
        font-weight: 500;
        margin-bottom: 40px;
      }
    }

    .slick-dots {
      text-align: right;

      li {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        border: 1px solid var(--primary-color);

        &.slick-active {
          background: var(--primary-color);
        }

        button {
          display: none;
        }
      }
    }
  }

  .client-wrapper {
    padding: 50px 0 34px;
    background: #212329;
  }

  @media (max-width: 576px) {
    .testim-box {
      position: relative;
      right: -10px;
      padding: 50px 20px;
      width: calc(100% - 20px);
      top: -400px;

      .head-box {
        h4 {
          font-size: 20px;
        }
      }
    }

    .client-wrapper {
      margin-top: -400px;
      padding: 50px 0 34px;
      background: #212329;
    }
  }
`;

export const ParallaxBanner = styled(P)`
  > div:nth-child(2) {
    z-index: 1;
    min-height: 700px;
    position: relative;

    > * {
      z-index: 1;
    }

    .vid-area {
      position: absolute;
      top: 0px;
      left: 5%;
      padding: 30px 15px;
      background: #ffff;
      text-align: center;
      z-index: 2;

      &:after {
        content: '';
        position: absolute;
        top: 0px;
        left: -10px;
        right: -10px;
        bottom: -10px;
        background: #fff;
        opacity: 0.2;
        z-index: -1;
      }

      .text {
        display: block;
        color: #000;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        transform: rotate(-90deg);
        margin-top: 30px;
      }

      button {
        margin-top: 50px;
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #080718;
    opacity: 0.5;
  }
`;

export const Item = styled.div`
  p {
    font-weight: 300;
    line-height: 2;
    margin: 0;
    font-size: 17px;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  .info {
    .img-box {
      display: table-cell;
      vertical-align: middle;

      .p-avatar {
        width: 70px;
        height: 70px;
      }
    }

    .author {
      display: table-cell;
      vertical-align: middle;
      padding-left: 15px;

      .author-name {
        font-size: 16px;
        margin-bottom: 5px;
        letter-spacing: 2px;
        text-transform: uppercase;
      }

      .author-details {
        font-size: 13px;
        font-weight: 300;
        text-transform: uppercase;
      }
    }
  }
`;

const FadeInUp = keyframes`
  0% {
    opacity: 0;
   transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const ClientItem = styled.div`
  text-align: center;
  // border: 1px solid rgba(255, 255, 255, 0.08);
  height: 100px;
  line-height: 100px;
  // margin-bottom: 30px;
  display: flex;
  position: relative;
  cursor: pointer;

  img {
    transition: all 0.4s;
    max-width: 75px;
    margin: auto;
    width: 100%;
    height: auto;
  }

  h6 {
    font-size: 13px;
    width: max-content;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: all 0.3s;
    font-weight: 400;
  }

  &:hover {
    img {
      transform: scale(0);
      opacity: 0;
    }
    h6 {
      opacity: 1 !important;
    }
  }
`;

interface Props {
  index: number;
}
export const SPAN = styled.span<Props>`
  display: inline-block;
  animation: ${FadeInUp} 1s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation-delay: ${({ index }) => `calc(60ms * ${index})`};

  ${Item}:hover & {
  }
`;
