import { Section } from './index.styled';
import { Container } from 'components/container';
import { BGTitleText } from 'components/text';

const About = () => {
  return (
    <Section id="about-section">
      <Container>
        <div className="grid">
          <div className="col-12 md:col-5">
            <div className="grid">
              <div className="col-12 md:col-5">
                <img src="/assets/images/dcommerce.png" alt="" />
              </div>
              <div className="col-12 md:col-7">
                <img src="/assets/images/3.png" alt="" />
                <img src="/assets/images/mobile_block.png" alt="" />
              </div>
            </div>
          </div>
          <div className="col-12 md:col-6 md:col-offset-1 head">
            <BGTitleText>ABOUT CRWDSHOP</BGTitleText>
            <h3>
              We are more than just
              <br /> a digital website builder
              <br /> technology.
            </h3>
            <p>
              We are crwdshop. We create award-winning websites, remarkable brands and cutting-edge distributed commerce
              on the blockchain! Weare ONLINE artisans who will jump start you with templates, help you with your
              visionor take your ideas and run with it!
            </p>

            <ul className="ftbox">
              <li>
                <span className="icon pe-7s-gleam" />
                <h6>
                  Pixel <br /> Perfect
                </h6>
                <div className="dots">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </li>
              <li>
                <span className="icon pe-7s-paint-bucket" />
                <h6>
                  Creative <br /> Design
                </h6>
                <div className="dots">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </li>
              <li>
                <span className="icon pe-7s-medal" />
                <h6>
                  High <br /> Perfomance
                </h6>
                <div className="dots">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default About;
