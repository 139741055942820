import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  padding: 80px 0;

  .title {
    font-weight: 700;
    font-size: 38px;
    line-height: 1.3;
    color: #fff;
  }

  .des {
    color: var(--s-color);
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .box {
    position: relative;
    padding: 0 10px;
    height: 350px;
    border-right: 2px solid var(--p-color);
    border-bottom: 2px solid var(--p-color);

    &:last-child {
      border-right: none;
    }

    ul {
      padding-left: 20px;
      margin: 0;
    }

    h3 {
      color: var(--p-color);
      font-size: 21px;
      line-height: 23px;
      letter-spacing: 0px;
      position: absolute;
      bottom: 10px;
    }
  }

  @media (max-width: 576px) {
  }
`;
