import { Container } from 'components/container';
import data from './data.json';
import { Section } from './index.styled';
import Heading from '../heading';
import parse from 'html-react-parser';

const NewEra = () => {
  return (
    <Section>
      <Container>
        <Heading text="NEW ERA FOR INTERNET AND FINANCE" textColor="dark" />
        <h4 className="title my-5">
          CrowdPoint returns value to consumers when they interact online by aligning incentives and giving rebates to
          users
        </h4>

        <div className="grid mt-5 pt-5">
          {data.map((f, i) => (
            <div key={i} className="col-2 box">
              <ul>
                {f.items.map((item, j) => (
                  <li key={i + j}>{item}</li>
                ))}
              </ul>
              <h3 className="subTitle">{parse(f.label)}</h3>
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default NewEra;
