import styled from 'styled-components';

export const Section = styled.section`
  .breadcrumbs {
    background: transparent;
    padding-top: 230px;

    h3 {
      font-size: 80px;
      font-weight: 600;
    }
  }

  .main {
    padding: 120px 0;
    background: var(--surface-section);

    h3 {
      font-weight: 700;
      line-height: 1.4;
      font-size: 30px;
      display: inline-block;
      margin-bottom: 50px;
    }

    h4 {
      margin-top: 60px;
      font-weight: 700;
      font-size: 30px;
    }

    h5 {
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 2px;
      margin-bottom: 10px;

      &.second {
        margin-top: 40px;
      }
    }

    p {
      font-size: 14px;
      font-weight: 300;
      margin: 10px 0;
    }

    fieldset {
      max-width: unset;
    }

    input,
    textarea {
      font-family: var(--font-family);
      font-size: 1rem;
      color: var(--text-color);
      background: var(--surface-section);
      padding: 0.75rem 0.75rem;
      border: 1px solid var(--surface-border);
      transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s,
        background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
      appearance: none;
      border-radius: 4px;
      width: -webkit-fill-available !important;

      &:hover {
        border-color: var(--text-color);
      }
    }

    .inputs-list {
      padding-left: 20px;
      margin: 5px 0 20px;
      color: var(--danger-color);

      label {
        font-size: 12px;
      }
    }

    .actions {
      text-align: right;
      margin-right: 8px;

      .hs-button {
        border-radius: 4px;
        font-weight: 400;
        font-size: 13px;
        margin: 0;
        transition: all 0.4s;
        padding: 15px 35px;
        border: 1px solid transparent;
        box-shadow: none !important;
        background: #fff;
        color: #121212;
        width: auto !important;

        &:hover {
          box-shadow: none !important;
          opacity: 0.8;
        }

        &:active {
          box-shadow: none !important;
          opacity: 0.4;
        }
      }
    }

    .submitted-message {
      color: var(--text-color);
      font-size: 16px;
    }

    .social {
      margin: 50px 5px 0px !important;

      button {
        box-shadow: none;
        background: rgba(255, 255, 255, 0.01);
        padding: 14px 15px;
      }
    }
  }

  .map-wrapper {
    width: 100%;
    height: 60vh;

    > div {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 576px) {
    .breadcrumbs {
      background: transparent;
      padding-top: 220px;

      h3 {
        font-size: 40px;
        font-weight: 600;
      }
    }
  }
`;
