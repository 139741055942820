import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  padding: 50px 0 0;

  .box {
    margin-top: 100px;
    background: var(--primary-color);

    .des {
      font-family: 'DM Mono', monospace;
      color: var(--s-color);
      font-size: 13px;
      font-weight: 500;
      text-transform: uppercase;
    }

    ul {
      padding-left: 22px;

      li {
        font-size: 20px;
        font-weight: 500;
        color: var(--s-color);
        line-height: 1.2;
      }
    }

    .inner-box {
      background: #fff;
      height: 100%;
      border-radius: 40px;
      padding: 40px 10px 40px;
      margin-top: -45px;

      .card {
        margin: 0;
        align-items: center;
        margin-bottom: 20px;

        h5 {
          color: var(--s-color);
          font-size: 15px;
          font-weight: 600;
        }
        p {
          margin: 0;
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 576px) {
  }
`;
